import React from 'react';
import './App.css';

import Api from './constants/Api'

import './assets/css/stack-interface.css'
import './assets/css/socicon.css'
import './assets/css/iconsmind.css'
import './assets/css/bootstrap.css'
import './assets/css/theme-red.css'
import './assets/css/custom.css'
import './assets/css/font-awesome.min.css'
import './assets/css/flickity.css'
import './assets/scss/app.scss'

import SiteApp from './pages/App'

export default class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    return (
      <SiteApp/>
    )
  }

}
