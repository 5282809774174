import React from "react";
import INDEED_WHITE_LOGO from "../../assets/images/indeed_logo_white.png";

const LOGO_GIF = require('../../assets/images/Amply_Ecosystem_GIF.gif')

export default class PreLoad extends React.Component{
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {

    return (
      <>
        <div id="preload" style={{position: 'fixed',top: 0,bottom: 0,left: 0,right: 0,zIndex: 9992,backgroundColor: '#232323'}} className="text-center">
          <div style={{objectFit:'contain',position: 'absolute',transform: 'translate(-50%, -70%)',top: '50%',left: '50%'}}>
            <img src={LOGO_GIF} style={{height: '50%',width: '50%'}}/>
            <h1 className="pb-xl-5 text-center type--bold" style={{color: '#ffffff', whiteSpace: 'nowrap'}}>Building Your Website... Drum Roll Please</h1>
            <img className="logo logo-dark" alt="logo" src={INDEED_WHITE_LOGO} style={{height: 100}} />
          </div>
        </div>
      </>
    )
  }
}
